import React, { Component } from "react";
import NavBar from "../NavBar/NavBar";

class ErrorPage extends Component {
  render() {
    return (
      <div>
        <NavBar {...this.props} />
        <br />
        <div
          style={{
            textAlign: "center",
          }}
        >
          <h1>Sorry !!! Token expired.</h1>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
